import "@fontsource/dm-mono";
import "@fontsource/dm-sans";
import { MantineProvider } from "@mantine/core";
import { DatesProvider } from "@mantine/dates";
import "dayjs/locale/hu";
import "dayjs/locale/ro";
import React, { useEffect, useState } from "react";
import {
  I18nextProvider,
  useTranslation,
  withTranslation,
} from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { Footer } from "./components/Footer";
import { Navbar } from "./components/Navbar";
import StickyAcceptTerms from "./components/StickyAccept";
import i18n from "./i18n";
import CookiePolicy from "./pages/CookiePolicy";
import InProgress from "./pages/InProgress";
import PrivacyPolicy from "./pages/PrivatePolicy";
import { TermsAndConditions } from "./pages/TermsAndConditions";
const queryClient = new QueryClient();

const App: React.FC = () => {
  const [isInIframe, setIsInIframe] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const inIframe = window.self !== window.top;
    setIsInIframe(inIframe);
  }, []);

  return (
    <MantineProvider>
      <QueryClientProvider client={queryClient}>
        <I18nextProvider i18n={i18n}>
          <DatesProvider
            settings={{ locale: "en", firstDayOfWeek: 0, weekendDays: [0] }}
          >
            <Router>
              <div>
                {!isInIframe && (
                  <Navbar
                    links={[
                      {
                        link: "https://www.zarpet.net/contact",
                        label: t("navbar.contact"),
                      },
                      {
                        link: "https://zarpet.net",
                        label: t("navbar.aboutUs"),
                      },
                      {
                        link: "https://zarpet.net/preturi",
                        label: t("navbar.prices"),
                      },
                      {
                        link: "https://zarpet.net/blog",
                        label: t("navbar.blog"),
                      },
                    ]}
                  />
                )}
                <Routes>
                  <Route
                    path="*"
                    element={<Navigate to="/verifica" replace />}
                  />
                  <Route path="/verifica" Component={InProgress} />
                  <Route path="/verifica/:paramId" Component={InProgress} />
                  <Route
                    path="/termeni-si-conditii"
                    Component={TermsAndConditions}
                  />
                  <Route
                    path="/politica-de-confidentialitate"
                    Component={PrivacyPolicy}
                  />
                  <Route path="/politica-de-cookies" Component={CookiePolicy} />
                </Routes>
                <StickyAcceptTerms
                  setReloadGA={() => {
                    console.log("accepted");
                  }}
                />

                <Footer
                  links={[
                    {
                      link: "/verifica",
                      label: t("footer.home"),
                    },
                    {
                      link: "https://www.zarpet.net/#contact",
                      label: t("footer.contact"),
                    },
                  ]}
                />
              </div>
            </Router>
          </DatesProvider>
        </I18nextProvider>
      </QueryClientProvider>
    </MantineProvider>
  );
};

export default withTranslation()(App);
