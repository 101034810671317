import { IOrder } from "../pages/InProgress";

const loadOrder = async (id: string) => {
  const response = await fetch(
    ` ${process.env.REACT_APP_COVOARE_CURATE_API}/v1/status/${id}`
  );
  if (!response.ok) throw new Error("Something went wrong");

  const data = await response.json();
  return data;
};

const updateOrder = async (orderToUpdate: IOrder) => {
  const { id, ...rest } = orderToUpdate;
  const response = await fetch(
    ` ${process.env.REACT_APP_COVOARE_CURATE_API}/v1/status/${id}`,
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(rest),
    }
  );
  if (!response.ok) throw new Error("Something went wrong");

  const data = await response.json();
  return data;
};
const updateTermsAndConditions = async (orderId: string) => {
  const response = await fetch(
    ` ${process.env.REACT_APP_COVOARE_CURATE_API}/v1/status/${orderId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (!response.ok) throw new Error("Something went wrong");
  return response.json();
};

const api = {
  loadOrder,
  updateOrder,
  updateTermsAndConditions,
};
export default api;
