import { Button, Card, Collapse, Text, createStyles } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconArrowBarDown, IconArrowBarUp } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IItem } from "../pages/InProgress";

const useStyle = createStyles((theme) => ({
  title: {
    fontSize: "22px",
    fontWeight: "bold",
    color: theme.colors.dark[9],
    margin: "0px",
    padding: "0px",
    fontFamily: "DM Mono, sans-serif",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  subtitle: {
    fontSize: "18px",
    color: theme.colors.dark[9],
    margin: "0px",
    padding: "0px",
    fontFamily: "DM Mono, sans-serif",
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #000",
    width: "100%",
  },
  values: {
    fontSize: "18px",
    fontWeight: "bold",
    color: theme.colors.teal[7],
    margin: "0px",
    padding: "0px",
    fontFamily: "DM Mono, sans-serif",
    borderBottom: "1px solid #000",
    // paddingLeft: "20px",
    width: "100%",
  },
}));
interface IProps {
  items?: IItem[];
  isMobile: boolean;
  organizationCurrency?: string;
  totalPrice?: number;
  restToMinimumOrder?: number;
  minimumOrder?: number;
}

function ItemsTable({
  items,
  isMobile,
  organizationCurrency = "LEI",
  totalPrice: totalPriceIncoming,
  restToMinimumOrder,
  minimumOrder,
}: IProps) {
  const { classes } = useStyle();
  const [opened, { toggle }] = useDisclosure(false);
  const { t } = useTranslation();

  const [totalPrice, setTotalPrice] = useState<number>(0);
  useEffect(() => {
    if (totalPriceIncoming && restToMinimumOrder && minimumOrder) {
      setTotalPrice(minimumOrder);
    } else if (totalPriceIncoming && !minimumOrder && !restToMinimumOrder) {
      setTotalPrice(totalPriceIncoming);
    } else if (totalPriceIncoming) {
      setTotalPrice(totalPriceIncoming);
    } else {
      setTotalPrice(0);
    }
  }, [totalPriceIncoming, restToMinimumOrder, minimumOrder]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "10px",
        width: "100%", // Ensure parent container occupies full width
        marginBottom: "20px",
        padding: "10px",
        boxSizing: "border-box",
        fontSize: "25px",
        borderTop: "3px solid #fff",
      }}
    >
      {totalPrice && (
        <div>
          {t("totalPriceLabel")}
          <Text
            color="teal.4"
            style={{
              fontSize: "30px",
              fontWeight: "bold",
              fontFamily: "DM Mono, sans-serif",
            }}
          >
            {totalPrice.toFixed(2)} {organizationCurrency}
          </Text>
        </div>
      )}
      <Button
        size="lg"
        fullWidth
        onClick={toggle}
        color="teal.7"
        leftIcon={opened ? <IconArrowBarUp /> : <IconArrowBarDown />}
      >
        {!opened ? t("buttonShowMeasuredItems") : t("buttonHideMeasuredItems")}
      </Button>

      <Collapse
        in={opened}
        transitionDuration={300}
        transitionTimingFunction="linear"
        style={{
          boxSizing: "border-box",
          width: "100%",
        }}
      >
        <div style={{ width: "100%" }}>
          {items &&
            items.map((item, index) => (
              <Card
                key={item.id}
                shadow="xs"
                padding="sm"
                radius="md"
                style={{
                  marginBottom: "10px",
                  width: "100%",
                  backgroundColor: "#D0EBFF",
                }}
                withBorder={false}
              >
                <div className={classes.title}>
                  <>{item.name}</> <>{" #" + (index + 1)}</>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      width: isMobile ? "100%" : "30%",
                    }}
                  >
                    {item.type === "carpet" && (
                      <>
                        <div className={classes.subtitle}> {t("lenght")}: </div>
                        <div className={classes.subtitle}>{t("width")}: </div>
                      </>
                    )}
                    <div className={classes.subtitle}>{t("quantity")}: </div>
                    <div className={classes.subtitle}>{t("priceUnit")}: </div>
                    <div className={classes.subtitle}>{t("totalPrice")}: </div>
                  </div>
                  <div
                    style={{
                      width: isMobile ? "100%" : "20%",
                    }}
                  >
                    {item.type === "carpet" && (
                      <>
                        <div className={classes.values}>
                          {item.length || 1} m
                        </div>
                        <div className={classes.values}>
                          {item.width || 1} m
                        </div>
                      </>
                    )}
                    <div className={classes.values}>
                      {item.type === "carpet"
                        ? `${((item.length || 1) * (item.width || 1)).toFixed(
                            2
                          )} mp`
                        : `${item.quantity || 1} buc`}
                    </div>
                    <div className={classes.values}>
                      {item.pricePerUnit} {organizationCurrency}
                    </div>
                    <div className={classes.values}>
                      {(
                        item.pricePerUnit *
                        (item.type === "carpet"
                          ? (item.length || 1) * (item.width || 1)
                          : item.quantity || 1)
                      ).toFixed(2)}
                      {organizationCurrency}
                    </div>
                  </div>
                </div>
              </Card>
            ))}
          {!!restToMinimumOrder && (
            <Card
              key={"123231321321"}
              shadow="xs"
              padding="sm"
              radius="md"
              style={{
                marginBottom: "10px",
                width: "100%",
                backgroundColor: "#D0EBFF",
              }}
              withBorder={false}
            >
              <div className={classes.title}>
                <>{t("remainingRest")}</>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: isMobile ? "100%" : "30%",
                  }}
                >
                  <div className={classes.subtitle}>{t("quantity")}: </div>
                  <div className={classes.subtitle}>{t("priceUnit")}: </div>
                  <div className={classes.subtitle}>{t("totalPrice")}: </div>
                </div>
                <div
                  style={{
                    width: isMobile ? "100%" : "20%",
                  }}
                >
                  <div className={classes.values}>1 </div>
                  <div className={classes.values}>
                    {restToMinimumOrder} {organizationCurrency}
                  </div>
                  <div className={classes.values}>
                    {restToMinimumOrder} {organizationCurrency}
                  </div>
                </div>
              </div>
            </Card>
          )}
        </div>
      </Collapse>
    </div>
  );
}

export default ItemsTable;
