import { Button, Loader, TextInput } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useGetOrder } from "../hooks/useGetOrder";
import { IOrder } from "../pages/InProgress";

interface IProps {
  setOrder: (order: IOrder | null) => void;
  isMobile: boolean;
  order?: IOrder | null;
  reloadOrder: boolean;
}
function Search({ setOrder, isMobile, order, reloadOrder }: IProps) {
  const navigate = useNavigate();
  const [searchTriggered, setSearchTriggered] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const { data, isLoading, isError, isSuccess, refetch } = useGetOrder();
  const { paramId } = useParams();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadOrder]);
  useEffect(() => {
    if (data && isSuccess) {
      setOrder(data);
      setSearchValue("");
      i18n.changeLanguage(
        data?.organizationLanguage?.toLocaleLowerCase() || "ro"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isSuccess]);

  useEffect(() => {
    if (order?.organizationLanguage) {
      localStorage.setItem(
        "language-client",
        order?.organizationLanguage?.toLocaleLowerCase()
      );
    }
  }, [order?.organizationLanguage, i18n.language]);

  useEffect(() => {
    if (searchValue) navigate(`/verifica/${searchValue}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTriggered]);
  return (
    <>
      {isLoading ? (
        <Loader
          color="teal.7"
          size={80}
          style={{
            // in the middle of the screen
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "10px",
            width: isMobile ? "100%" : "50%",
            padding: "10px",
            boxSizing: "border-box",
            border: "3px solid #fff",
            borderRadius: "4px",
          }}
        >
          {order ? (
            <Button
              color="teal.7"
              fullWidth
              onClick={() => {
                setOrder(null);
              }}
              style={{
                height: "50px",
              }}
              leftIcon={<IconSearch size={25} color="white" />}
            >
              {t("researchButton")}
            </Button>
          ) : (
            <>
              <TextInput
                error={
                  isError &&
                  searchValue &&
                  searchValue === paramId &&
                  t("errorSearch")
                }
                value={searchValue}
                onChange={(event) => {
                  setSearchValue(event.currentTarget.value.toLocaleUpperCase());
                }}
                icon={
                  <IconSearch
                    size={25}
                    color={
                      isError && searchValue && searchValue === paramId
                        ? "#FF6B6B"
                        : "grey"
                    }
                  />
                }
                label={t("searchLabel")}
                placeholder={t("searchPlaceholder")}
                styles={{
                  root: {
                    width: "100%",
                    boxSizing: "border-box",
                  },
                  label: {
                    color: "#fff",
                    fontSize: "20px",
                    fontFamily: "DM Mono, sans-serif",
                    marginBottom: "10px",
                  },
                  input: {
                    height: "50px",
                    fontSize: "30px",
                    borderColor:
                      isError && searchValue && searchValue === paramId
                        ? "#FF6B6B"
                        : "grey",
                    color:
                      isError && searchValue && searchValue === paramId
                        ? "#FF6B6B !important"
                        : "#000",
                  },
                  error: {
                    color: "#FF6B6B",
                    fontSize: "20px",
                    fontFamily: "DM Mono, sans-serif",
                  },
                }}
              />
              <Button
                color="teal.7"
                fullWidth
                style={{
                  width: "100%",
                  padding: "10px",
                  boxSizing: "border-box",
                  fontSize: "20px",
                  fontFamily: "DM Mono, sans-serif",
                  height: "50px",
                }}
                onClick={() => {
                  setSearchTriggered((o) => !o);
                }}
                loading={isLoading}
                leftIcon={<IconSearch size={25} color="white" />}
                disabled={!searchValue}
              >
                {t("searchButton")}
              </Button>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default Search;
