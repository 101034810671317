import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Translation resources
const resources = {
  en: {
    translation: {
      contactOrganization:
        "Contact the laundry quickly by pressing the buttons below",
      callOrganization: "Call laundry",
      openWhatsApp: "Open WhatsApp",
      copyLink: "Copy link",
      remainingRest: "Remaining minimum order",
      searchLabel: "Enter the verification code from the receipt",
      searchPlaceholder: "Receipt code",
      errorSearch: "The verification code is not valid",
      searchButton: "Search",
      researchButton: "Search again",
      statusPickedUp: "Items picked up",
      statusMeasured: "Items measured",
      statusInProgress: "Items are in progress",
      statusWashed: "Items washed",
      statusForDelivery: "Items ready for delivery",
      statusForDeliveryInHouse: "Items can be picked up from store",
      statusDelivered: "Items delivered",
      statusUnknown: "Status unknown",
      whatsAppMessage:
        "Hi,%20I%20have%20washed%20some%20items%20at%20your%20place,%20I%20have%20some%20questions%20about%20them%.%20Order%20ID:%20",
      shareLinkCopied: "Link copied",
      shareButton: "Share",
      labelId: "Order ID",
      labelName: "Name",
      labelPhone: "Phone",
      labelNrOfProducts: "Number of picked up items",
      labelDatePickup: "Pickup date",
      labelDateDelivery: "Delivery date",
      labelAproxDateDelivery: "Approximate Delivery Date",
      labelBetweenHours: "Between Hours",
      labelMessageDay: "You sent the day",
      labelMessageHour: "at",
      messageEnd:
        "as the preferred date. If you want to change it, press the button below and add a new preferred date.",
      buttonAddFavoriteDate: "Add Preferred Date",
      labelSelectDate: "Select Preferred Date",
      labelSelectHour: "Select Preferred Hour",
      labelObservations: "Observations",
      labelObservationsPlaceholder: "Write your observations here",
      buttonSendRquest: "Send Rescheduling Request",
      messageDeliveryDone:
        "The order has been delivered! How satisfied are you with our services?",
      buttonSendFeedback: "Send feedback",
      feedbackSentThanks: "The feedback has been sent. Thank you!",
      totalPriceLabel: "Total to pay",
      buttonShowMeasuredItems: "Show measured items",
      buttonHideMeasuredItems: "Hide measured items",
      length: "Length",
      width: "Width",
      quantity: "Quantity",
      priceUnit: "Unit price",
      totalPrice: "Total price",
      navbar: {
        home: "Home",
        contact: "Contact Zarpet",
        features: "Features",
        videoTutorials: "Video Tutorials",
        aboutUs: "About Us",
        reviews: "Reviews",
        prices: "Prices",
        blog: "Blog",
      },
      footer: {
        home: "Home",
        contact: "Contact",
        checkOrder: "Check Order",
        termsAndConditions: "Terms and Conditions",
        privacyPolicy: "Privacy Policy",
        cookiesPolicy: "Cookies Policy",
      },
    },
  },
  ro: {
    translation: {
      contactOrganization:
        "Contactează rapid spălătoria apăsând butoanele de mai jos",
      callOrganization: "Sunați spalatoria",
      openWhatsApp: "Contact WhatsApp",
      copyLink: "Copiază link",
      remainingRest: "Rest comanda minima",
      searchLabel: "Introduceți codul de verificare de pe bon",
      searchPlaceholder: "Cod de pe bon",
      errorSearch: "Codul de verificare nu este valid",
      searchButton: "Caută",
      researchButton: "Caută din nou",
      statusPickedUp: "Produse ridicate",
      statusMeasured: "Produse măsurate",
      statusInProgress: "Produsele sunt în curs de prelucrare",
      statusWashed: "Produse spălate",
      statusForDelivery: "Produse pregătite pentru livrare",
      statusForDeliveryInHouse: "Produsele pot fi ridicate de la sediu",
      statusDelivered: "Produse livrate",
      statusUnknown: "Nu se cunoaște statusul",
      whatsAppMessage:
        "Salut,%20am%20spalat%20niste%20produse%20la%20voi,%20as%20avea%20niste%20intrebari%20despre%20ele%.%20ID%20comanda:%20",
      shareLinkCopied: "Link copiat",
      shareButton: "Distribuie",
      labelId: "ID comanda",
      labelName: "Nume",
      labelPhone: "Telefon",
      labelNrOfProducts: "Numar produse ridicate",
      labelDatePickup: "Data preluare",
      labelDateDelivery: "Data livrare",
      labelAproxDateDelivery: "Data livrare aproximativa",
      labelBetweenHours: "Între orele",
      labelMessageDay: "Ați trimis ziua",
      labelMessageHour: "de la ora",
      messageEnd: `ca și preferată. Dacă doriți să o schimbați, apăsați butonul de mai
      jos și adăugați o nouă dată preferată.`,
      buttonAddFavoriteDate: "Adaugă dată preferată",
      labelSelectDate: "Selectați data preferată",
      labelSelectHour: "Selectați ora preferată",
      labelObservations: "Observații",
      labelObservationsPlaceholder: "Scrie aici observații",
      buttonSendRquest: "Trimite cererea de reprogramare",
      messageDeliveryDone:
        "Comanda a fost livrata! Cat de multumit esti de serviciile noastre?",
      buttonSendFeedback: "Trimite feedback",
      feedbackSentThanks: "Feedback-ul a fost trimis. Multumim!",
      totalPriceLabel: "Total de plată",
      buttonShowMeasuredItems: "Arată produsele măsurate",
      buttonHideMeasuredItems: "Ascunde produsele măsurate",
      lenght: "Lungime",
      width: "Lățime",
      quantity: "Cantitate",
      priceUnit: "Preț unitar",
      totalPrice: "Preț total",
      navbar: {
        home: "Acasă",
        contact: "Contact",
        features: "Funcționalități",
        videoTutorials: "Tutoriale video",
        aboutUs: "Despre noi",
        reviews: "Recenzii",
        prices: "Preturi",
        blog: "Blog",
      },
      footer: {
        home: "Acasă",
        contact: "Contact Zarpet",
        checkOrder: "Verifică comanda",
        termsAndConditions: "Termeni și condiții",
        privacyPolicy: "Politica de confidențialitate",
        cookiesPolicy: "Politica de cookies",
      },
    },
  },
  hu: {
    translation: {
      contactOrganization:
        "A mosodával való gyors kapcsolatfelvételhez nyomja meg az alábbi gombokat",
      callOrganization: "Hívja a mosodát",
      openWhatsApp: "WhatsApp megnyitása",
      copyLink: "Link másolása",
      remainingRest: "Hátralévő minimális rendelés",
      searchLabel: "Írja be a blokk ellenőrző kódját",
      searchPlaceholder: "Blokk kód",
      errorSearch: "Az ellenőrző kód érvénytelen",
      searchButton: "Keresés",
      researchButton: "Újra keresés",
      statusPickedUp: "Felvehető elemek",
      statusMeasured: "Mért elemek",
      statusInProgress: "Elemek folyamatban",
      statusWashed: "Mosott elemek",
      statusForDelivery: "Kiszállításra kész elemek",
      statusForDeliveryInHouse: "Az elemek személyesen átvehetők",
      statusDelivered: "Kiszállított elemek",
      statusUnknown: "Ismeretlen állapot",
      whatsAppMessage:
        "Szia,%20mosást%20rendeltem%20tőletek,%20lenne%20néhány%20kérdésem%20velük%20kapcsolatban%20és%20szeretnék%20küldeni%20néhány%20képet.%20Rendelési%20azonosító:%20",
      shareLinkCopied: "Link másolva",
      shareButton: "Megosztás",
      labelId: "Rendelési azonosító",
      labelName: "Név",
      labelPhone: "Telefon",
      labelNrOfProducts: "Felvehető elemek száma",
      labelDatePickup: "Átvétel dátuma",
      labelDateDelivery: "Kiszállítás dátuma",
      labelAproxDateDelivery: "Becsült kiszállítási dátum",
      labelBetweenHours: "Órák között",
      labelMessageDay: "Elküldtétek",
      labelMessageHour: "óra",
      messageEnd:
        "ként előnyben részesített dátumot. Ha szeretnéd megváltoztatni, nyomd meg az alábbi gombot és adj hozzá egy új előnyben részesített dátumot.",
      buttonAddFavoriteDate: "Előnyben Részesített Dátum Hozzáadása",
      labelSelectDate: "Válassz Előnyben Részesített Dátumot",
      labelSelectHour: "Válassz Előnyben Részesített Órát",
      labelObservations: "Megfigyelések",
      labelObservationsPlaceholder: "Írd ide a megfigyeléseidet",
      buttonSendRquest: "Ütemezési Kérés Küldése",
      messageDeliveryDone:
        "A rendelés kiszállítva! Mennyire vagy elégedett a szolgáltatásainkkal?",
      buttonSendFeedback: "Visszajelzés Küldése",
      feedbackSentThanks: "A visszajelzés elküldve. Köszönjük!",
      totalPriceLabel: "Fizetendő összeg",
      buttonShowMeasuredItems: "Mért elemek mutatása",
      buttonHideMeasuredItems: "Mért elemek elrejtése",
      lenght: "Hossz",
      width: "Szélesség",
      quantity: "Mennyiség",
      priceUnit: "Egységár",
      totalPrice: "Teljes ár",
      footer: {
        home: "Kezdőlap",
        contact: "Kapcsolat Zarpet",
        checkOrder: "Rendelés ellenőrzése",
        termsAndConditions: "Felhasználási feltételek",
        privacyPolicy: "Adatvédelmi nyilatkozat",
        cookiesPolicy: "Sütik használata",
      },
      navbar: {
        home: "Kezdőlap",
        contact: "Kapcsolat",
        features: "Funkciók",
        videoTutorials: "Videó oktatóanyagok",
        aboutUs: "Rólunk",
        reviews: "Vélemények",
        prices: "Árak",
        blog: "Blog",
      },
    },
  },
  pl: {
    translation: {
      contactOrganization:
        "Skontaktuj się z pralnią szybko, naciskając poniższe przyciski",
      callOrganization: "Zadzwoń do pralni",
      openWhatsApp: "Otwórz WhatsApp",
      copyLink: "Skopiuj link",
      remainingRest: "Pozostałe minimum zamówienie",
      searchLabel: "Wprowadź kod weryfikacyjny z paragonu",
      searchPlaceholder: "Kod paragonu",
      errorSearch: "Kod weryfikacyjny jest nieprawidłowy",
      searchButton: "Szukaj",
      researchButton: "Szukaj ponownie",
      statusPickedUp: "Przedmioty odebrane",
      statusMeasured: "Przedmioty zmierzone",
      statusInProgress: "Przedmioty są w trakcie",
      statusWashed: "Przedmioty umyte",
      statusForDelivery: "Przedmioty gotowe do dostawy",
      statusForDeliveryInHouse: "Przedmioty można odebrać ze sklepu",
      statusDelivered: "Przedmioty dostarczone",
      statusUnknown: "Nieznany status",
      whatsAppMessage:
        "Cześć,%20umyłem%20kilka%20przedmiotów%20w%20Twoim%20miejscu,%20mam%20kilka%20pytań%20na%20ich%20temat%20i%20chciałbym%20przesłać%20Ci%20kilka%20zdjęć.%20ID%20zamówienia:%20",
      shareLinkCopied: "Link skopiowany",
      shareButton: "Udostępnij",
      labelId: "ID zamówienia",
      labelName: "Imię",
      labelPhone: "Telefon",
      labelNrOfProducts: "Liczba odebranych przedmiotów",
      labelDatePickup: "Data odbioru",
      labelDateDelivery: "Data dostawy",
      labelAproxDateDelivery: "Przybliżona data dostawy",
      labelBetweenHours: "Między godzinami",
      labelMessageDay: "Wysłałeś dzień",
      labelMessageHour: "o",
      messageEnd:
        "jako preferowaną datę. Jeśli chcesz ją zmienić, naciśnij poniższy przycisk i dodaj nową preferowaną datę.",
      buttonAddFavoriteDate: "Dodaj preferowaną datę",
      labelSelectDate: "Wybierz preferowaną datę",
      labelSelectHour: "Wybierz preferowaną godzinę",
      labelObservations: "Obserwacje",
      labelObservationsPlaceholder: "Napisz swoje obserwacje tutaj",
      buttonSendRquest: "Wyślij prośbę o zmianę terminu",
      messageDeliveryDone:
        "Zamówienie zostało dostarczone! Jak zadowolony jesteś z naszych usług?",
      buttonSendFeedback: "Wyślij opinię",
      feedbackSentThanks: "Opinia została wysłana. Dziękujemy!",
      totalPriceLabel: "Łączna kwota do zapłaty",
      buttonShowMeasuredItems: "Pokaż zmierzone przedmioty",
      buttonHideMeasuredItems: "Ukryj zmierzone przedmioty",
      length: "Długość",
      width: "Szerokość",
      quantity: "Ilość",
      priceUnit: "Cena jednostkowa",
      totalPrice: "Łączna cena",
      navbar: {
        home: "Strona główna",
        contact: "Skontaktuj się z Zarpetem",
        features: "Funkcje",
        videoTutorials: "Poradniki wideo",
        aboutUs: "O nas",
        reviews: "Recenzje",
        prices: "Ceny",
        blog: "Blog",
      },
      footer: {
        home: "Strona główna",
        contact: "Kontakt",
        checkOrder: "Sprawdź zamówienie",
        termsAndConditions: "Warunki i zasady",
        privacyPolicy: "Polityka prywatności",
        cookiesPolicy: "Polityka plików cookie",
      },
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "ro", // Set the default language
  fallbackLng: "ro", // Fallback language if translation is missing
  interpolation: {
    escapeValue: false, // React already escapes values by default
  },
});

export default i18n;
