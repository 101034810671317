import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import api from "../apiCalls/api";
export const useGetOrder = () => {
  const { paramId } = useParams();
  return useQuery(
    ["invoice-" + paramId],
    () => {
      if (paramId) {
        return api.loadOrder(paramId);
      }
    },
    { retry: false }
  );
};
