import { useMutation } from "react-query";
import api from "../apiCalls/api";

export const useAcceptTermsAndConditions = () => {
  return useMutation<any, Error, any>(
    //@ts-ignore
    (orderId: string) => api.updateTermsAndConditions(orderId)
  );
};

export default useAcceptTermsAndConditions;
