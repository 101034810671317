import {
  Container,
  Group,
  Header,
  Image,
  createStyles,
  rem,
} from "@mantine/core";
import LanguageSelector from "../smallComponents/LanguangeSelector";

const HEADER_HEIGHT = rem(60);

const useStyles = createStyles((theme) => ({
  inner: {
    height: HEADER_HEIGHT,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },

  links: {
    [theme.fn.smallerThan("sm")]: {
      //   display only the first children
      "& > *:not(:first-child)": {
        display: "none",
      },
      // and second children
      "& > *:nth-child(2)": {
        display: "block",
      },
    },
  },

  link: {
    display: "block",
    lineHeight: 1,
    padding: `${rem(8)} ${rem(8)}`,
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color: "#fff",
    fontWeight: 400,
    fontFamily: "montserrat, sans-serif",
    fontSize: "16px",
    [theme.fn.smallerThan("md")]: {
      fontSize: "13px",
    },
    "&:hover": {
      color: theme.fn.themeColor("teal", 5),
      // a small delay on hover color change
      transition: "color 500ms ease",
    },
  },

  linkLabel: {
    marginRight: rem(5),
  },
}));

interface HeaderActionProps {
  links: { link: string; label: string }[];
}

export function Navbar({ links }: HeaderActionProps) {
  const { classes } = useStyles();

  const items = links.map((link) => {
    return (
      <a
        key={link.label}
        href={link.link}
        className={classes.link}
        onClick={(event) => {
          event.preventDefault();
          window.location.href = link.link;
        }}
      >
        {link.label}
      </a>
    );
  });
  // .filter(
  //   isMobile
  //     ? //only the first and last link
  //       (link, index) => index === 0 || index === links.length - 1
  //     : //all links
  //       () => true
  // );

  return (
    <Header
      height={HEADER_HEIGHT}
      sx={{ borderBottom: 0, backgroundColor: "#161636", position: "sticky" }}
    >
      <Container className={classes.inner} fluid>
        <Group>
          <Image
            maw={90}
            mx="auto"
            src="../../transparent.png"
            alt="Zarpet logo"
          />
        </Group>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Group spacing={25} className={classes.links}>
            {items}
          </Group>
          <LanguageSelector />
        </div>
      </Container>
    </Header>
  );
}
