import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function TermsAndConditionsRo() {
  return (
    <div
      style={{
        fontFamily: "DM Sans, sans-serif",
        fontSize: "16px",
        marginBottom: 100,
        marginInline: 30,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <h1
        style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "20px" }}
      >
        Termeni și Condiții
      </h1>
      <p>Ultima actualizare 05.08.2023</p>
      <div
        style={{
          width: "60%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
          // height: `calc(100vh - ${NAVBAR_HEIGHT})`,
        }}
      >
        <iframe
          title="Termeni si conditii"
          width="100%"
          height="100%"
          style={{
            border: "1px solid black",
          }}
          src="https://zarpet-legal.s3.eu-central-1.amazonaws.com/1.+Termeni+s%CC%A6i+condit%CC%A6ii_SPD.pdf"
        ></iframe>
      </div>
      {/* <p>
        Prin utilizarea aplicației Zarpet, sunteți de acord să respectați
        următorii Termeni și Condiții. Acești termeni se aplică atât companiilor
        de curățare a covoarelor și hainelor care utilizează sistemul Zarpet,
        cât și clienților acestora care accesează aplicația comanda.zarpet.net.
      </p>

      <h2 style={{ fontSize: "20px", fontWeight: "bold", marginTop: "30px" }}>
        Utilizarea Aplicației
      </h2>

      <p>
        Accesul și utilizarea aplicației Zarpet sunt permise doar în
        conformitate cu legile și reglementările aplicabile. Nu sunteți
        autorizat să utilizați aplicația în scopuri ilegale sau într-un mod care
        ar putea afecta securitatea sau funcționalitatea aplicației sau a altor
        utilizatori.
      </p>

      <h2 style={{ fontSize: "20px", fontWeight: "bold", marginTop: "30px" }}>
        Proprietatea Intelectuală
      </h2>

      <p>
        Toate drepturile de proprietate intelectuală asupra aplicației Zarpet și
        conținutului acesteia (incluzând, dar fără a se limita la, text,
        grafică, logo-uri, imagini, clipuri audio și video) sunt deținute de
        SOFT PEAK DIGITAL SRL. Nu aveți permisiunea de a copia, distribui sau
        utiliza acest conținut în orice alt mod fără acordul scris al
        proprietarului.
      </p>

      <h2 style={{ fontSize: "20px", fontWeight: "bold", marginTop: "30px" }}>
        Limitarea Răspunderii
      </h2>

      <p>
        SOFT PEAK DIGITAL SRL nu poate fi făcută responsabilă pentru orice
        prejudiciu, pierdere sau daune directe sau indirecte cauzate de
        utilizarea sau imposibilitatea de utilizare a aplicației Zarpet.
        Utilizarea aplicației se face pe propriul risc.
      </p>

      <h2 style={{ fontSize: "20px", fontWeight: "bold", marginTop: "30px" }}>
        Modificări ale Termenilor și Condițiilor
      </h2>

      <p>
        SOFT PEAK DIGITAL SRL își rezervă dreptul de a modifica acești Termeni
        și Condiții în orice moment. Versiunea actualizată a Termenilor și
        Condițiilor va fi publicată pe site-ul web zarpet.net. Continuarea
        utilizării aplicației după efectuarea modificărilor constituie acceptul
        dumneavoastră implicit al acestor modificări.
      </p> */}

      <h2 style={{ fontSize: "20px", fontWeight: "bold", marginTop: "30px" }}>
        Contact
      </h2>

      <p>
        Pentru orice întrebări sau nelămuriri referitoare la acești Termeni și
        Condiții, vă rugăm să ne contactați la adresa de e-mail:{" "}
        <a href="mailto:contact@zarpet.net">contact@zarpet.net</a>.
      </p>
    </div>
  );
}
function TermsAndConditionsEn() {
  return (
    <div
      style={{
        fontFamily: "DM Sans, sans-serif",
        fontSize: "16px",
        marginBottom: 100,
        marginInline: 30,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <h1
        style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "20px" }}
      >
        Terms and Conditions
      </h1>
      <p>Last update 05.08.2023</p>

      <div
        style={{
          width: "60%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
          // height: `calc(100vh - ${NAVBAR_HEIGHT})`,
        }}
      >
        <iframe
          title="Termeni si conditii"
          width="100%"
          height="100%"
          style={{
            border: "1px solid black",
          }}
          src="https://zarpet-legal.s3.eu-central-1.amazonaws.com/1.+Termeni+s%CC%A6i+condit%CC%A6ii_SPD.pdf"
        ></iframe>
      </div>
      {/* <p>
        By using the Zarpet application, you agree to abide by the following
        Terms and Conditions. These terms apply to both carpet and clothing
        cleaning companies using the Zarpet system and their customers accessing
        the comanda.zarpet.net application.
      </p>

      <h2 style={{ fontSize: "20px", fontWeight: "bold", marginTop: "30px" }}>
        Use of the Application
      </h2>

      <p>
        Access to and use of the Zarpet application is only permitted in
        accordance with applicable laws and regulations. You are not authorized
        to use the application for illegal purposes or in a way that could
        affect the security or functionality of the application or other users.
      </p>

      <h2 style={{ fontSize: "20px", fontWeight: "bold", marginTop: "30px" }}>
        Intellectual Property
      </h2>

      <p>
        All intellectual property rights to the Zarpet application and its
        content (including, but not limited to, text, graphics, logos, images,
        audio, and video clips) are owned by SOFT PEAK DIGITAL SRL. You are not
        permitted to copy, distribute, or use this content in any other way
        without the written consent of the owner.
      </p>

      <h2 style={{ fontSize: "20px", fontWeight: "bold", marginTop: "30px" }}>
        Limitation of Liability
      </h2>

      <p>
        SOFT PEAK DIGITAL SRL cannot be held liable for any direct or indirect
        damages, losses, or injuries caused by the use or inability to use the
        Zarpet application. The use of the application is at your own risk.
      </p>

      <h2 style={{ fontSize: "20px", fontWeight: "bold", marginTop: "30px" }}>
        Changes to the Terms and Conditions
      </h2>

      <p>
        SOFT PEAK DIGITAL SRL reserves the right to modify these Terms and
        Conditions at any time. The updated version of the Terms and Conditions
        will be published on the zarpet.net website. Continued use of the
        application after the modifications constitutes your implied acceptance
        of these changes.
      </p> */}

      <h2 style={{ fontSize: "20px", fontWeight: "bold", marginTop: "30px" }}>
        Contact
      </h2>

      <p>
        For any questions or concerns regarding these Terms and Conditions,
        please contact us at{" "}
        <a href="mailto:contact@zarpet.net">contact@zarpet.net</a>.
      </p>
    </div>
  );
}
function TermsAndConditionsHu() {
  return (
    <div
      style={{
        fontFamily: "DM Sans, sans-serif",
        fontSize: "16px",
        marginBottom: 100,
        marginInline: 30,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <h1
        style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "20px" }}
      >
        Felhasználási Feltételek
      </h1>
      <p>Utolsó frissítés: 2023.08.05</p>
      <div
        style={{
          width: "60%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
          // height: `calc(100vh - ${NAVBAR_HEIGHT})`,
        }}
      >
        <iframe
          title="Termeni si conditii"
          width="100%"
          height="100%"
          style={{
            border: "1px solid black",
          }}
          src="https://zarpet-legal.s3.eu-central-1.amazonaws.com/1.+Termeni+s%CC%A6i+condit%CC%A6ii_SPD.pdf"
        ></iframe>
      </div>
      {/* <p>
        A Zarpet alkalmazás használatával elfogadja az alábbi Felhasználási
        Feltételeket. Ezek a feltételek vonatkoznak a Zarpet rendszerét használó
        szőnyeg- és ruhatisztító cégekre és az ügyfeleikre, akik az
        comanda.zarpet.net alkalmazást használják.
      </p>

      <h2 style={{ fontSize: "20px", fontWeight: "bold", marginTop: "30px" }}>
        Az alkalmazás használata
      </h2>

      <p>
        Az alkalmazás Zarpet alkalmazása csak a vonatkozó törvények és
        szabályozások szerint engedélyezett. Nem jogosult az alkalmazás
        jogellenes célra történő felhasználására vagy olyan módon történő
        felhasználására, amely az alkalmazás biztonságát vagy funkcionalitását,
        valamint más felhasználókét befolyásolhatja.
      </p>

      <h2 style={{ fontSize: "20px", fontWeight: "bold", marginTop: "30px" }}>
        Szellemi Tulajdon
      </h2>

      <p>
        Az alkalmazás Zarpet és annak tartalma (beleértve, de nem kizárólagosan
        a szöveget, grafikákat, logókat, képeket, hang- és videoklippeket)
        minden szellemi tulajdonjoga a SOFT PEAK DIGITAL SRL tulajdonában áll.
        Nem jogosult ezt a tartalmat másolni, terjeszteni vagy más módon
        felhasználni a tulajdonos írásbeli engedélye nélkül.
      </p>

      <h2 style={{ fontSize: "20px", fontWeight: "bold", marginTop: "30px" }}>
        Felelősség Korlátozása
      </h2>

      <p>
        A SOFT PEAK DIGITAL SRL nem vállal felelősséget az alkalmazás Zarpet
        használatából eredő közvetlen vagy közvetett károkért, veszteségekért
        vagy sérülésekért. Az alkalmazás használata saját kockázatára történik.
      </p>

      <h2 style={{ fontSize: "20px", fontWeight: "bold", marginTop: "30px" }}>
        Feltételek Módosítása
      </h2>

      <p>
        A SOFT PEAK DIGITAL SRL fenntartja magának a jogot, hogy bármikor
        módosítsa ezeket a Felhasználási Feltételeket. A Felhasználási
        Feltételek frissített változata az zarpet.net webhelyen lesz közzétéve.
        Az alkalmazás további használata a módosítások után azoknak az elvárt
        módosításoknak való megfelelés elfogadását jelenti.
      </p> */}

      <h2 style={{ fontSize: "20px", fontWeight: "bold", marginTop: "30px" }}>
        Kapcsolat
      </h2>

      <p>
        A Felhasználási Feltételekkel kapcsolatos kérdések vagy észrevételek
        esetén kérjük, lépjen kapcsolatba velünk a következő e-mail címen:{" "}
        <a href="mailto:contact@zarpet.net">contact@zarpet.net</a>.
      </p>
    </div>
  );
}
export function TermsAndConditions() {
  const { i18n } = useTranslation();
  const [component, setComponent] = useState(<></>);
  useEffect(() => {
    switch (i18n.language) {
      case "ro":
        setComponent(<TermsAndConditionsRo />);
        break;
      case "en":
        setComponent(<TermsAndConditionsEn />);
        break;
      case "hu":
        setComponent(<TermsAndConditionsHu />);
        break;
      default:
        setComponent(<TermsAndConditionsRo />);
        break;
    }
  }, [i18n.language]);

  return <>{component}</>;
}
