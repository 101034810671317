import {
  Button,
  Center,
  Loader,
  Rating,
  RingProgress,
  Textarea,
  ThemeIcon,
  rem,
  useMantineTheme,
} from "@mantine/core";
import {
  IconClipboardCheck,
  IconMoodCrazyHappy,
  IconMoodCry,
  IconMoodEmpty,
  IconMoodHappy,
  IconMoodSad,
  IconMoodSmile,
} from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
interface IProps {
  orderId: string;
  giveFeedback: (orderId: string, rating: number, comment: string) => void;
}

function RatingAndComments({ orderId, giveFeedback }: IProps) {
  const theme = useMantineTheme();
  const [rating, setRating] = useState<number>(0);
  const [comment, setComment] = useState<string>("");
  const [ratingSubmitted, setRatingSubmitted] = useState<boolean>(false);
  const [valueProgressRing, setValueProgressRing] = useState<number>(0);
  const { t, i18n } = useTranslation();
  console.log("i18n.language", i18n.language);
  useEffect(() => {
    if (ratingSubmitted) {
      //simulate a progress bar by increasing the value of the progress ring
      const interval = setInterval(() => {
        setValueProgressRing((prevValue) =>
          prevValue < 100 ? prevValue + 1 : prevValue
        );
      }, 5);
      return () => clearInterval(interval);
    }
  }, [ratingSubmitted]);

  const getEmptyIcon = (value: number) => {
    const defaultProps = { size: rem(48), color: "white" };
    switch (value) {
      case 1:
        return <IconMoodCry {...defaultProps} />;
      case 2:
        return <IconMoodSad {...defaultProps} />;
      case 3:
        return <IconMoodSmile {...defaultProps} />;
      case 4:
        return <IconMoodHappy {...defaultProps} />;
      case 5:
        return <IconMoodCrazyHappy {...defaultProps} />;
      default:
        return <IconMoodEmpty {...defaultProps} />;
    }
  };

  const getFullIcon = (value: number) => {
    const defaultProps = { size: rem(48) };

    switch (value) {
      case 1:
        return <IconMoodCry {...defaultProps} color={theme.colors.red[7]} />;
      case 2:
        return <IconMoodSad {...defaultProps} color={theme.colors.orange[7]} />;
      case 3:
        return (
          <IconMoodSmile {...defaultProps} color={theme.colors.yellow[7]} />
        );
      case 4:
        return <IconMoodHappy {...defaultProps} color={theme.colors.lime[7]} />;
      case 5:
        return (
          <IconMoodCrazyHappy {...defaultProps} color={theme.colors.green[7]} />
        );
      default:
        return <IconMoodEmpty {...defaultProps} />;
    }
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "10px",
        fontSize: "20px",
        fontWeight: "bold",
        width: "100%",
        padding: "10px",
        boxSizing: "border-box",
        borderTop: "3px solid #fff",
        borderBottom: "3px solid #fff",
      }}
    >
      {!ratingSubmitted ? (
        <>
          <div>{t("messageDeliveryDone")}</div>
          <Rating
            emptySymbol={getEmptyIcon}
            fullSymbol={getFullIcon}
            highlightSelectedOnly
            onChange={(value) => setRating(value)}
            value={rating}
          />
          <Textarea
            value={comment}
            onChange={(event) => setComment(event.currentTarget.value)}
            styles={{
              label: {
                color: "#fff",
                fontSize: "20px",
                fontFamily: "DM Mono, sans-serif",
              },
            }}
            label={t("labelObservations")}
            placeholder={t("labelObservationsPlaceholder")}
            style={{
              width: "100%",
            }}
          />
          <Button
            color="teal.7"
            radius="sm"
            size="lg"
            fullWidth
            disabled={!rating}
            onClick={() => {
              giveFeedback(orderId, rating, comment);
              setRatingSubmitted(true);
            }}
          >
            {t("buttonSendFeedback")}
          </Button>
        </>
      ) : (
        <>
          <div> {t("feedbackSentThanks")}</div>
          <RingProgress
            sections={[{ value: valueProgressRing, color: "teal.5" }]}
            label={
              <Center>
                <ThemeIcon color="teal.5" variant="light" radius="xl" size="xl">
                  {valueProgressRing === 100 ? (
                    <IconClipboardCheck size={22} />
                  ) : (
                    <Loader size="xs" />
                  )}
                </ThemeIcon>
              </Center>
            }
          />
        </>
      )}
    </div>
  );
}
export default RatingAndComments;
