import english16 from "./en-16.png";
import english32 from "./en-32.png";
import hungarian16 from "./hu-16.png";
import hungarian32 from "./hu-32.png";
import romanian16 from "./ro-16.png";
import romanian32 from "./ro-32.png";
import polish16 from "./pl-16.png";
import polish32 from "./pl-32.png";


const images = {
  english16,
  english32,
  hungarian16,
  hungarian32,
  romanian16,
  romanian32,
  polish16,
  polish32
};

export default images;
