import { useMutation } from "react-query";
import api from "../apiCalls/api";
import { IOrder } from "../pages/InProgress";

export const useUpdateOrder = () => {
  return useMutation<any, Error, any>(
    //@ts-ignore
    (orderToUpdate: IOrder) => api.updateOrder(orderToUpdate)
  );
};

export default useUpdateOrder;
