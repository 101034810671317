import {
  Button,
  Collapse,
  Select,
  Textarea,
  createStyles,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useDisclosure } from "@mantine/hooks";
import { IconCalendar } from "@tabler/icons-react";
import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/hu";
import "dayjs/locale/ro";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IOrder } from "../pages/InProgress";
export const useStyle = createStyles((theme) => ({
  key: {
    fontSize: "18px",
    color: "#fff",
    margin: "0px",
    padding: "0px",
    fontFamily: "DM Mono, sans-serif",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    gap: "10px",
    width: "100%",
  },
  values: {
    fontSize: "18px",
    color: theme.fn.themeColor("teal.3"),
    margin: "0px",
    padding: "0px",
    fontFamily: "DM Mono, sans-serif",
  },
  sameLine: {
    fontFamily: "DM Mono, sans-serif",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    width: "100%",
  },

  firstColumn: {
    flex: "1 0 70%", // Grow to fill space, do not shrink, start at 50% width
    maxWidth: "70%", // or any other suitable maximum width
    "@media (min-width: 768px)": {
      // This is for screens larger than 768px
      maxWidth: "40%", // Align items to the start on large screens
    },
  },

  secondColumn: {
    flex: "0 1 auto", // Do not grow, allow shrink, basis is content size
  },

  textStyle: {
    color: "#fff",
    fontFamily: "DM Mono, sans-serif",
  },
  actionIcons: {
    // Basic shadow for depth
    boxShadow: "0 3px 6px rgba(0, 0, 0, 0.16)",
    // Rounded corners for a modern, touch-friendly design
    borderRadius: "8px",
    // Sufficient padding for a finger-sized touch target
    // Interactive cursor on hover
    cursor: "pointer",
    // Transition for smooth visual feedback

    // Visual feedback for interaction
    "&:hover": {
      // Slightly larger shadow for a "lifted" effect
      boxShadow: "0 5px 10px rgba(0, 0, 0, 0.2)",
    },
    "&:active": {
      // Move the button slightly to simulate a press
      transform: "translateY(1px)",
      // Diminish the shadow as if the button is being pressed
      boxShadow: "0 2px 3px rgba(0, 0, 0, 0.1)",
    },
  },
}));
interface IProps {
  order: IOrder;
  aproxDeliveryDateFormatted: string;
  aproximateHourDelivery?: string;
  updateDeliveryDate: (
    orderId: string,
    date: Date,
    reason: string,
    time: string
  ) => void;
}
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

function ReprogramDelivery({
  order,
  updateDeliveryDate,
  aproxDeliveryDateFormatted,
  aproximateHourDelivery,
}: IProps) {
  const { classes } = useStyle();
  const [value, setValue] = useState<Date | null>(null);
  const [hour, setHour] = useState<string>("");
  const [reason, setReason] = useState<string>("");
  const [opened, { toggle }] = useDisclosure(false);
  const { t, i18n } = useTranslation();
  dayjs.locale(i18n.language);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: "10px",
        fontSize: "20px",
        fontWeight: "bold",
        width: "100%",
        boxSizing: "border-box",
        color: "#fff",
      }}
    >
      <div className={classes.key}>
        {t("labelAproxDateDelivery")}:{" "}
        <span className={classes.values}>{aproxDeliveryDateFormatted}</span>
      </div>
      <div>
        {" "}
        {t("labelBetweenHours")}: {aproximateHourDelivery}
      </div>
      {order.preferredDeliveryDate && (
        <p>
          {t("labelMessageDay")}:{" "}
          <span className={classes.values}>
            {dayjs(order.deliveryDateChanged?.date).format("dddd, D MMMM YYYY")}
          </span>{" "}
          {t("labelMessageHour")}{" "}
          <span className={classes.values}>
            {order.deliveryDateChanged?.time}
          </span>{" "}
          {t("messageEnd")}
        </p>
      )}
      {order.abilityToAddPreferredDeliveryDate && (
        <Button
          color="teal.7"
          variant="filled"
          size="lg"
          fullWidth
          leftIcon={<IconCalendar size={30} />}
          onClick={() => toggle()}
        >
          {t("buttonAddFavoriteDate")}
        </Button>
      )}

      <Collapse
        in={opened}
        transitionDuration={300}
        transitionTimingFunction="linear"
        style={{
          boxSizing: "border-box",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: "10px",
            fontSize: "20px",
            fontWeight: "bold",
            width: "100%",
            boxSizing: "border-box",
            color: "#fff",
          }}
        >
          <DateInput
            value={value}
            valueFormat="dddd, D MMMM YYYY"
            onChange={setValue}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            // close on click outside

            onFocus={(e) => {
              e.target.readOnly = true;
              // on iphone the keyboard is not hidden when the input is blurred
              // so we need to hide it manually
              if (document.activeElement instanceof HTMLElement) {
                document.activeElement.blur();
              }
            }}
            label={t("labelSelectDate")}
            placeholder={t("labelSelectDate")}
            color="white"
            style={{
              width: "100%",
            }}
            locale={i18n.language}
            excludeDate={(date) => {
              // Disable all dates before today
              if (dayjs(date).isBefore(dayjs().subtract(1, "day"))) {
                return true;
              }
              return false;
            }}
            styles={{
              label: {
                color: "#fff",
                fontSize: "20px",
                fontFamily: "DM Mono, sans-serif",
              },
            }}
          />
          <Select
            value={hour}
            style={{
              width: "100%",
            }}
            styles={{
              label: {
                color: "#fff",
                fontSize: "20px",
                fontFamily: "DM Mono, sans-serif",
              },
            }}
            onChange={(changedValue) => changedValue && setHour(changedValue)}
            data={[
              { value: "08:00", label: "08:00" },
              { value: "09:00", label: "09:00" },
              { value: "10:00", label: "10:00" },
              { value: "11:00", label: "11:00" },
              { value: "12:00", label: "12:00" },
              { value: "13:00", label: "13:00" },
              { value: "14:00", label: "14:00" },
              { value: "15:00", label: "15:00" },
              { value: "16:00", label: "16:00" },
              { value: "17:00", label: "17:00" },
              { value: "18:00", label: "18:00" },
              { value: "19:00", label: "19:00" },
            ]}
            label={t("labelSelectHour")}
            placeholder={t("labelSelectHour")}
          />
          <Textarea
            styles={{
              label: {
                color: "#fff",
                fontSize: "20px",
                fontFamily: "DM Mono, sans-serif",
              },
            }}
            value={reason}
            onChange={(event) => setReason(event.currentTarget.value)}
            label={t("labelObservations")}
            placeholder={t("labelObservationsPlaceholder")}
            style={{
              width: "100%",
            }}
          />
          <Button
            color="teal"
            variant="light"
            radius="sm"
            size="lg"
            fullWidth
            onClick={() => {
              toggle();
              updateDeliveryDate(
                order.generatedTicketId,
                value ? value : new Date(),
                reason,
                hour
              );
            }}
            disabled={!value || !hour}
          >
            {t("buttonSendRquest")}
          </Button>
        </div>
      </Collapse>
    </div>
  );
}

export default ReprogramDelivery;
