import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const PrivacyPolicyRo = () => {
  return (
    <div
      style={{
        fontFamily: "DM Sans, sans-serif",
        fontSize: "16px",
        marginBottom: 100,
        marginInline: 30,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <h1
        style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "20px" }}
      >
        Politica de Confidențialitate
      </h1>
      <p>Ultima actualizare: 05.08.2023</p>
      <div
        style={{
          width: "60%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
          // height: `calc(100vh - ${NAVBAR_HEIGHT})`,
        }}
      >
        <iframe
          title="Termeni si conditii"
          width="100%"
          height="100%"
          style={{
            border: "1px solid black",
          }}
          src="https://zarpet-legal.s3.eu-central-1.amazonaws.com/3.+Politica+de+confidentialitate+site_SPD.pdf"
        ></iframe>
      </div>
      <h2 style={{ fontSize: "20px", fontWeight: "bold", marginTop: "30px" }}>
        Contact
      </h2>

      <p>
        Pentru orice întrebări sau nelămuriri referitoare la această Politică de
        Confidențialitate, vă rugăm să ne contactați la adresa de e-mail:{" "}
        <a href="mailto:contact@zarpet.net">contact@zarpet.net</a>.
      </p>
    </div>
  );
};
// PrivacyPolicyEn.js
const PrivacyPolicyEn = () => {
  return (
    <div
      style={{
        fontFamily: "DM Sans, sans-serif",
        fontSize: "16px",
        marginBottom: 100,
        marginInline: 30,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <h1
        style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "20px" }}
      >
        Privacy Policy
      </h1>
      <p>Last update: 2023.08.05</p>
      <div
        style={{
          width: "60%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
          // height: `calc(100vh - ${NAVBAR_HEIGHT})`,
        }}
      >
        <iframe
          title="Termeni si conditii"
          width="100%"
          height="100%"
          style={{
            border: "1px solid black",
          }}
          src="https://zarpet-legal.s3.eu-central-1.amazonaws.com/3.+Politica+de+confidentialitate+site_SPD.pdf"
        ></iframe>
      </div>

      <h2 style={{ fontSize: "20px", fontWeight: "bold", marginTop: "30px" }}>
        Contact
      </h2>

      <p>
        For any questions or inquiries regarding this Privacy Policy, please
        contact us at the email address:{" "}
        <a href="mailto:contact@zarpet.net">contact@zarpet.net</a>.
      </p>
    </div>
  );
};

// PrivacyPolicyHu.js
const PrivacyPolicyHu = () => {
  return (
    <div
      style={{
        fontFamily: "DM Sans, sans-serif",
        fontSize: "16px",
        marginBottom: 100,
        marginInline: 30,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <h1
        style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "20px" }}
      >
        Adatvédelmi Nyilatkozat
      </h1>
      <p>Utolsó frissítés: 2023.08.05</p>
      <div
        style={{
          width: "60%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
          // height: `calc(100vh - ${NAVBAR_HEIGHT})`,
        }}
      >
        <iframe
          title="Termeni si conditii"
          width="100%"
          height="100%"
          style={{
            border: "1px solid black",
          }}
          src="https://zarpet-legal.s3.eu-central-1.amazonaws.com/3.+Politica+de+confidentialitate+site_SPD.pdf"
        ></iframe>
      </div>

      <h2 style={{ fontSize: "20px", fontWeight: "bold", marginTop: "30px" }}>
        Kapcsolat
      </h2>

      <p>
        Bármilyen kérdés vagy érdeklődés esetén az Adatvédelmi Nyilatkozattal
        kapcsolatban, kérjük, lépjen velünk kapcsolatba az alábbi e-mail címen:{" "}
        <a href="mailto:contact@zarpet.net">contact@zarpet.net</a>.
      </p>
    </div>
  );
};

const PrivacyPolicy = () => {
  const { i18n } = useTranslation();
  const [component, setComponent] = useState(<></>);
  useEffect(() => {
    switch (i18n.language) {
      case "ro":
        setComponent(<PrivacyPolicyRo />);
        break;
      case "en":
        setComponent(<PrivacyPolicyEn />);
        break;
      case "hu":
        setComponent(<PrivacyPolicyHu />);
        break;
      default:
        setComponent(<PrivacyPolicyEn />);
        break;
    }
  }, [i18n.language]);

  return <>{component}</>;
};

export default PrivacyPolicy;
