import { Button, Modal, ScrollArea } from "@mantine/core";
import { useEffect } from "react";
import useAcceptTermsAndConditions from "../hooks/useAcceptTermsAndConditions";
interface IProps {
  terms: string;
  orderId: string;
  opened: boolean;
  onClose: () => void;
}
function ModalAcceptTerms({ terms, orderId, opened, onClose }: IProps) {
  const { mutate: mutateOrder, isSuccess } = useAcceptTermsAndConditions();
  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <Modal
      centered
      title="Terms and Conditions"
      onClose={() => {}}
      opened={opened}
      withCloseButton={false}
      styles={{
        inner: {
          width: "90vw",
        },
      }}
    >
      <ScrollArea
        style={{
          height: "60vh",
          border: "1px solid black",
          borderRadius: "5px",
          padding: "10px",
          maxWidth: "80vw",
        }}
      >
        <div style={{}} dangerouslySetInnerHTML={{ __html: terms }}></div>
      </ScrollArea>

      <Button
        style={{
          marginTop: "20px",
        }}
        color="green"
        fullWidth
        onClick={() => {
          mutateOrder(orderId);
        }}
      >
        Accept
      </Button>
    </Modal>
  );
}

export default ModalAcceptTerms;
