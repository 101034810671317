import { Container, Group, createStyles, rem } from "@mantine/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
  footer: {
    backgroundColor: "#161636",
    borderTop: `${rem(1)} solid ${theme.colors.gray[2]}`,
    boxSizing: "border-box",
    maxWidth: "100%",
    width: "100%",
  },

  inner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "right",
    width: "100%",
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
    boxSizing: "border-box",
    maxWidth: "100%",
    [theme.fn.smallerThan("xs")]: {
      flexDirection: "column",
    },
  },
  link: {
    color: "#fff",
    textDecoration: "none",
  },
  links: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    boxSizing: "border-box",
    maxWidth: "100%",
    width: "100%",
    [theme.fn.smallerThan("xs")]: {
      marginTop: theme.spacing.md,
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-end",
    },
  },
}));

interface FooterSimpleProps {
  links: { link: string; label: string }[];
}
const termsAndPrivacy = [
  {
    link: "/verifica",
    label: "footer.checkOrder",
  },
  {
    link: "/termeni-si-conditii",
    label: "footer.termsAndConditions",
  },
  {
    link: "/politica-de-confidentialitate",
    label: "footer.privacyPolicy",
  },
  {
    link: "/politica-de-cookies",
    label: "footer.cookiesPolicy",
  },
];
export function Footer({ links }: FooterSimpleProps) {
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();
  const [footerLinks, setFooterLinks] = useState<any>([]);
  useEffect(() => {
    const items = links.map((link) => (
      <a
        className={classes.link}
        key={link.label}
        href={link.link}
        onClick={(event) => {
          event.preventDefault();
          window.location.href = link.link;
        }}
      >
        {link.label}
      </a>
    ));

    // Links to terms and privacy policy
    termsAndPrivacy.forEach((link) => {
      items.push(
        <a
          className={classes.link}
          key={link.label}
          href={link.link}
          onClick={(event) => {
            event.preventDefault();
            window.location.href = link.link;
          }}
        >
          {t(link.label)}
        </a>
      );
    });
    setFooterLinks(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <div className={classes.footer}>
      <Container className={classes.inner}>
        <Group className={classes.links}>{footerLinks}</Group>
      </Container>
    </div>
  );
}
