import { ActionIcon, Image, Text, Tooltip } from "@mantine/core";
import {
  IconBrandWhatsapp,
  IconCopy,
  IconPhoneOutgoing,
} from "@tabler/icons-react";
import "dayjs/locale/en";
import "dayjs/locale/hu";
import "dayjs/locale/ro";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IOrder } from "../pages/InProgress";
import { useStyle } from "./ReprogramDelivery";
interface IProps {
  order: IOrder;
}

export function ContactOrganization({ order }: IProps) {
  const { classes } = useStyle();

  const { t } = useTranslation();
  const [opened, setOpened] = useState<boolean>(false);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
        gap: "10px",
        boxSizing: "border-box",
        paddingBottom: "20px",
        borderBottom: "3px solid #fff",
        paddingInline: "10px",
      }}
    >
      {order?.organizationLogo && (
        <Image
          src={order?.organizationLogo}
          height={100}
          radius="md"
          maw={300}
          fit="cover"
          caption={order?.organizationName || ""}
          styles={{
            caption: {
              color: "#fff",
              fontSize: "20px",
              fontWeight: "bold",
              fontFamily: "DM Mono, sans-serif",
            },
          }}
        />
      )}

      <Text
        style={{
          fontSize: "20px",
          fontWeight: "bold",
          fontFamily: "DM Mono, sans-serif",
        }}
        fw={700}
        size="lg"
        color="teal.4"
      >
        {t("contactOrganization")}
      </Text>
      <div className={classes.sameLine}>
        <div className={classes.firstColumn}>
          {/* First column content here */}
          <Text className={classes.textStyle} fw={600} size="md">
            {t("callOrganization")}:{" "}
          </Text>
        </div>
        <div className={classes.secondColumn}>
          {/* Second column content here */}
          <ActionIcon
            className={classes.actionIcons}
            onClick={() => {
              window.location.href = `tel:${order.organizationPhone}`;
            }}
            size="xl"
            variant="filled"
            color="teal.7"
          >
            <IconPhoneOutgoing size={22} />
          </ActionIcon>
        </div>
      </div>
      <div className={classes.sameLine}>
        <div className={classes.firstColumn}>
          {/* First column content here */}
          <Text className={classes.textStyle} fw={600} size="md">
            {t("openWhatsApp")}:{" "}
          </Text>
        </div>
        <div className={classes.secondColumn}>
          {/* Second column content here */}
          <ActionIcon
            className={classes.actionIcons}
            onClick={() => {
              // open whatsapp with message to write to organization about the order saying Salut, am spalat niste produse la voi, as avea niste intrebari despre ele si as vrea sa va trimit niste poze. ID comanda: 123456
              window.open(
                `https://wa.me/4${order.organizationPhone}?text=${t(
                  "whatsAppMessage"
                )}${order.generatedTicketId}`
              );
            }}
            size="xl"
            variant="filled"
            color="teal.7"
          >
            <IconBrandWhatsapp size={30} />
          </ActionIcon>
        </div>
      </div>
      <div className={classes.sameLine}>
        <div className={classes.firstColumn}>
          {/* First column content here */}
          <Text className={classes.textStyle} fw={600} size="md">
            {t("copyLink")}:{" "}
          </Text>
        </div>
        <div className={classes.secondColumn}>
          {/* Second column content here */}
          <Tooltip
            label={t("shareLinkCopied")}
            opened={opened}
            withArrow
            transitionProps={{ duration: 200 }}
          >
            <ActionIcon
              className={classes.actionIcons}
              onClick={() => {
                const copyText = window.location.href;

                if (navigator.clipboard && navigator.clipboard.writeText) {
                  navigator.clipboard.writeText(copyText);
                } else {
                  // Fallback for iOS and other incompatible browsers
                  const tempTextArea = document.createElement("textarea");
                  tempTextArea.value = copyText;
                  document.body.appendChild(tempTextArea);
                  tempTextArea.select();
                  document.execCommand("copy");
                  document.body.removeChild(tempTextArea);
                }

                setOpened((o) => !o);
                setTimeout(() => {
                  setOpened((o) => !o);
                }, 500);
              }}
              size="xl"
              variant="filled"
              color="teal.7"
            >
              <IconCopy size={30} />
            </ActionIcon>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}
