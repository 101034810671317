import { Center, RingProgress, ThemeIcon } from "@mantine/core";
import {
  IconBuildingFactory,
  IconCheck,
  IconClipboardCheck,
  IconRulerMeasure,
  IconShoppingCart,
  IconTruckDelivery,
  IconWash,
} from "@tabler/icons-react";
import TranslateToReadStatus from "../smallComponents/StatusBadge";

function ProgressRing({ status }: { status: string }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "30px",
        paddingBottom: "20px",
        width: "100%",
        borderBottom: "3px solid #fff",
      }}
    >
      {status === "pickedUp" && (
        <RingProgress
          sections={[{ value: 20, color: "teal.7" }]}
          label={
            <Center>
              <ThemeIcon color="teal.7" variant="light" radius="xl" size="xl">
                <IconBuildingFactory size={22} />
              </ThemeIcon>
            </Center>
          }
        />
      )}
      {status === "forDelivery" && (
        <RingProgress
          sections={[{ value: 80, color: "teal.7" }]}
          label={
            <Center>
              <ThemeIcon color="teal.7" variant="light" radius="xl" size="xl">
                <IconTruckDelivery size={22} />
              </ThemeIcon>
            </Center>
          }
        />
      )}
      {status === "forDeliveryInHouse" && (
        <RingProgress
          sections={[{ value: 80, color: "teal.7" }]}
          label={
            <Center>
              <ThemeIcon color="teal.7" variant="light" radius="xl" size="xl">
                <IconShoppingCart size={22} />
              </ThemeIcon>
            </Center>
          }
        />
      )}
      {status === "delivered" && (
        <RingProgress
          sections={[{ value: 100, color: "teal" }]}
          label={
            <Center>
              <ThemeIcon color="teal" variant="light" radius="xl" size="xl">
                <IconCheck size={22} />
              </ThemeIcon>
            </Center>
          }
        />
      )}
      {status === "measured" && (
        <RingProgress
          sections={[{ value: 40, color: "teal.7" }]}
          label={
            <Center>
              <ThemeIcon color="teal.7" variant="light" radius="xl" size="xl">
                <IconRulerMeasure size={22} />
              </ThemeIcon>
            </Center>
          }
        />
      )}
      {status === "washed" && (
        <RingProgress
          sections={[{ value: 60, color: "teal.7" }]}
          label={
            <Center>
              <ThemeIcon color="teal.7" variant="light" radius="xl" size="xl">
                <IconClipboardCheck size={22} />
              </ThemeIcon>
            </Center>
          }
        />
      )}
      {status === "inProgress" && (
        <RingProgress
          sections={[{ value: 60, color: "teal.7" }]}
          label={
            <Center>
              <ThemeIcon color="teal.7" variant="light" radius="xl" size="xl">
                <IconWash size={22} />
              </ThemeIcon>
            </Center>
          }
        />
      )}

      <TranslateToReadStatus status={status} />
    </div>
  );
}

export default ProgressRing;
