import { Button, Paper } from "@mantine/core";
import { useEffect, useState } from "react";

interface IProps {
  setReloadGA: any;
}
const StickyAcceptTerms = ({ setReloadGA }: IProps) => {
  const [accepted, setAccepted] = useState(false);

  useEffect(() => {
    const isAccepted = localStorage.getItem("termsAccepted");
    if (isAccepted === "true") {
      setAccepted(true);
    }
  }, []);

  const handleAcceptance = () => {
    setAccepted(true);
    localStorage.setItem("termsAccepted", "true");
    window.location.reload(); // Reload the page to apply the changes in index.html
  };

  if (accepted) {
    // Render your main content if terms are accepted
    return <></>;
  }

  // Render the sticky component for accepting terms
  return (
    <Paper
      shadow="xs"
      radius="sm"
      style={{
        position: "fixed",
        bottom: "40px",
        left: "20px",
        width: "calc(100% - 40px)",
        backgroundColor: "#161636",
        color: "#ccc",
        fontFamily: "DM Sans, sans-serif",
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "10px 20px",
      }}
    >
      <p>
        Vă rugăm să citiți și să acceptați{" "}
        <a
          style={{
            color: "#ccc",
            textDecoration: "underline",
          }}
          href="/termeni-si-conditii"
        >
          Termenii și Condițiile
        </a>{" "}
        noastre pentru a putea continua.
      </p>
      <Button variant="default" onClick={handleAcceptance} color="blue.9">
        Accept
      </Button>
    </Paper>
  );
};

export default StickyAcceptTerms;
