import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function CookiePolicyRo() {
  return (
    <div
      style={{
        fontFamily: "DM Sans, sans-serif",
        fontSize: "16px",
        marginBottom: 100,
        marginInline: 30,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <h1>Politica de Cookie-uri</h1>
      <p>Ultima actualizare: 05.08.2023</p>
      <div
        style={{
          width: "60%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "80%",
          // height: `calc(100vh - ${NAVBAR_HEIGHT})`,
        }}
      >
        <iframe
          title="Politica de cookie"
          width="100%"
          height="100%"
          style={{
            border: "1px solid black",
          }}
          src="https://zarpet-legal.s3.eu-central-1.amazonaws.com/2.+Politica+cookies_SPD.docx.pdf"
        ></iframe>
      </div>
      <h2 style={{ fontSize: "20px", fontWeight: "bold", marginTop: "30px" }}>
        Contact
      </h2>

      <p>
        Pentru orice întrebări sau nelămuriri referitoare la acești Termeni și
        Condiții, vă rugăm să ne contactați la adresa de e-mail:{" "}
        <a href="mailto:contact@zarpet.net">contact@zarpet.net</a>.
      </p>
    </div>
  );
}

function CookiePolicyEn() {
  return (
    <div
      style={{
        fontFamily: "DM Sans, sans-serif",
        fontSize: "16px",
        marginBottom: 100,
        marginInline: 30,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <h1>Cookie Policy</h1>
      <p>Last updated: 05.08.2023</p>
      <div
        style={{
          width: "60%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "80%",
          // height: `calc(100vh - ${NAVBAR_HEIGHT})`,
        }}
      >
        <iframe
          title="Politica de cookie"
          width="100%"
          height="100%"
          style={{
            border: "1px solid black",
          }}
          src="https://zarpet-legal.s3.eu-central-1.amazonaws.com/2.+Politica+cookies_SPD.docx.pdf"
        ></iframe>
      </div>
      <h2 style={{ fontSize: "20px", fontWeight: "bold", marginTop: "30px" }}>
        Contact
      </h2>

      <p>
        For any questions or concerns regarding these Terms and Conditions,
        please contact us at{" "}
        <a href="mailto:contact@zarpet.net">contact@zarpet.net</a>.
      </p>
    </div>
  );
}

function CookiePolicyHu() {
  return (
    <div
      style={{
        fontFamily: "DM Sans, sans-serif",
        fontSize: "16px",
        marginBottom: 100,
        marginInline: 30,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <h1>Sütik</h1>
      <p>Utolsó frissítés: 05.08.2023</p>
      <div
        style={{
          width: "60%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "80%",
          // height: `calc(100vh - ${NAVBAR_HEIGHT})`,
        }}
      >
        <iframe
          title="Politica de cookie"
          width="100%"
          height="100%"
          style={{
            border: "1px solid black",
          }}
          src="https://zarpet-legal.s3.eu-central-1.amazonaws.com/2.+Politica+cookies_SPD.docx.pdf"
        ></iframe>
      </div>
      <h2 style={{ fontSize: "20px", fontWeight: "bold", marginTop: "30px" }}>
        Kapcsolat
      </h2>

      <p>
        A Felhasználási Feltételekkel kapcsolatos kérdések vagy észrevételek
        esetén kérjük, lépjen kapcsolatba velünk a következő e-mail címen:{" "}
        <a href="mailto:contact@zarpet.net">contact@zarpet.net</a>.
      </p>
    </div>
  );
}

const CookiePolicy = () => {
  const { i18n } = useTranslation();
  const [component, setComponent] = useState(<></>);
  useEffect(() => {
    switch (i18n.language) {
      case "ro":
        setComponent(<CookiePolicyRo />);
        break;
      case "en":
        setComponent(<CookiePolicyEn />);
        break;
      case "hu":
        setComponent(<CookiePolicyHu />);
        break;
      default:
        setComponent(<CookiePolicyEn />);
        break;
    }
  }, [i18n.language]);

  return <>{component}</>;
};

export default CookiePolicy;
