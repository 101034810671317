import { useTranslation } from "react-i18next";

import { Badge } from "@mantine/core";
interface IStatus {
  status: string;
}
function TranslateToReadStatus({ status }: IStatus) {
  const { t } = useTranslation();
  const statusFirstLetterUpperCase = status.charAt(0).toUpperCase();
  const statusRestOfWordLowerCase = status.slice(1);
  const statusNew = statusFirstLetterUpperCase + statusRestOfWordLowerCase;
  return (
    <>
      <Badge
        variant="filled"
        // gradient={{ from: "teal.7", to: "blue.7" }}
        color="teal.7"
        style={{
          wordBreak: "break-word",
          maxWidth: "100%",
          hyphens: "auto",
          whiteSpace: "normal",
          fontSize: "16px",
          height: "fit-content",
          margin: "10px",
        }}
        styles={{
          inner: {
            whiteSpace: "normal",
            textAlign: "center",
            padding: "5px 10px",
          },
        }}
      >
        {t(`status${statusNew}`)}
      </Badge>
    </>
  );
}

export default TranslateToReadStatus;
